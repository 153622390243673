<template>
  <div>
    <v-col cols="12">
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="height1"
            style="color: black"
            v-model="date"
            :label="$t('teslimTarihi')"
            :prepend-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
            @blur="getTarih"
          ></v-text-field>
        </template>

        <v-date-picker v-model="date" scrollable color="secondary">
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  data() {
    return { donusmusDeger: '' }
  },
  props: {
    uid: {
      default: null,
    },
    signalData: {
      type: Boolean,
    },
    temizle: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const id = props.uid
    //console.log(id)
    const date = ref('')
    const menu = ref(false)
    const modal = ref(false)
    const menu2 = ref(false)
    const menuref = ref(null)
    const tarih = ref('')

    //const formattedData = useDateFormat(date.value, 'DD.MM.YYYY')

    const getTarih = () => {
      tarih.value = date.value.replace(/-/g, '.')
      const parcalar = tarih.value.split('.')
      const donusmusDeger = `${parcalar[2]}.${parcalar[1]}.${parcalar[0]}`

      //console.log('formatlı tarih : ' + useNow().value)
      emit('deliveryDate', donusmusDeger)
    }

    watch(
      () => store.state.signal,
      () => {
        getTarih()
        date.value = ''
      },
    )

    return {
      date,
      menu,
      modal,
      menu2,
      menuref,
      getTarih,
      //formattedData,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style>
.height1 {
  height: 50px;
  display: flex;
  justify-content: flex-start;
}

.height2 {
  display: flex;
  justify-content: flex-start;
}
</style>
