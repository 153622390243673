<template>
  <div class="d-flex align-center justify-space-between scale-1">
    <v-btn
      depressed
      class="rounded-r-0"
      style="width: 32px; min-width: 32px"
      color="counter"
      light
      @click="minusNumber(BirimRitim)"
    >
      <v-icon small :color="number == 0 ? 'primary' : 'error'">
        {{ icons.mdiMinusThick }}
      </v-icon>
    </v-btn>

    <v-text-field
      ref="input"
      v-model.number="number"
      v-number-only
      suffix="AD"
      hide-details
      style="min-width: 70px"
      solo
      background-color="counter"
      light
      type="tel"
      flat
      dense
      :disabled="disabled"
      class="right-input rounded-0 font-weight-medium night--text"
      @blur="onBlurNumber(BirimRitim)"
      @focus="$event.target.select()"
    />

    <!--@blur="onBlurNumber(BirimRitim)" -->

    <v-sheet class="d-flex" :color="BgColor">
      <v-btn
        depressed
        class="rounded-l-0"
        :disabled="disabled"
        color="nightDark"
        style="width: 32px; min-width: 32px"
        @click="addNumber(BirimRitim)"
      >
        <v-icon small>
          {{ icons.mdiPlusThick }}
        </v-icon>
      </v-btn>
    </v-sheet>
    <!-- :color="number == 0 ? 'nightDark' : plantId == '01' ? 'secondary' : 'warning'"
       -->

    <v-btn @click="addnewBasket(BirimRitim)" class="ml-3" color="secondary">{{ $t('ekle') }}</v-btn>
  </div>
</template>

<script>
import store from '@/store'
import { mdiMinusThick, mdiPlusThick } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }

          return true
        }
        el.addEventListener('keypress', checkValue)
      },
    },
  },
  props: {
    signalData: {
      type: Boolean,
      default: true,
    },
    guncelle: {
      type: Boolean,
      default: true,
    },
    tarih: {
      type: String,
      default: '',
    },
    sonVeri: {
      type: String,
      default: '',
    },
    paymCond: {
      type: String,
    },
    bgColor: {
      type: String,
      default: 'secondary',
    },
    uid: {
      default: null,
    },
    plantId: {
      type: String,
      default: '01',
    },

    buttonColor: {
      type: String,
      default: 'white',
    },
    birimRitim: {
      type: Number,
      default: 1,
    },
    birim: {
      type: String,
      default: 'Adet',
    },
    miktar: {
      type: Number,
      default: 0,
    },
    miktar1: {
      type: Number,
      default: 0,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    cMaterial1: {
      type: String,
    },
    minSatis: {
      type: Number,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'refresh', 'info'],
  setup(props, { emit }) {
    const shoppingCartItems = ref([])
    const disabled = ref(false)
    const number = ref(props.minSatis)
    const Birim = ref(props.birim)
    const BirimRitim = ref(props.birimRitim)
    const BgColor = ref(props.bgColor)
    const ButtonColor = ref(props.buttonColor)
    const poNumber = ref(props.sonVeri)
    const deliveryDate = ref(props.tarih)
    const cMate = ref(props.cMaterial1)
    const signal = ref(props.signalData)
    const minSatis1 = ref(props.minSatis)

    onMounted(() => {
      number.value = props.minSatis
    })
    const info = (product, qty, birim) => {
      emit('addInfo', {})
    }

    const refresh = () => {
      emit('refreshBasket')
    }

    const addBasket = qty => {
      disabled.value = false

      if (qty === 0) {
        //çalışıyor
        const id = props.uid + '-'
        select()
        //const plant = props.plantId

        store.dispatch('app-order/fetchDeleteBasketItem', { id }).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      } else {
        emit('sinyal', signal.value)
        let veriler = {
          method: 'addToBasket',
          uid: props.uid,
          poNumber: props.sonVeri,
          deliveryDate: props.tarih,
          cMaterial: props.cMaterial1,
          qty,
        }

        store.dispatch('app-order/fetchAddToBasket', veriler).then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
        setTimeout(() => {
          disabled.value = false
        }, 300)
      }
    }

    const addnewBasket = val => {
      signal.value = !signal.value

      store.state.signal = !store.state.signal
      store.commit('SET_SIGNAL', store.state.signal)

      addBasket(number.value)

      let basildi = true
      let data = null
      if (number.value > 0) {
        data = true
      } else {
        data = false
      }
      select()

      emit('basildi', basildi)

      emit('gidenveri', data)
      emit('sinyal', signal.value)
    }

    const addNumber = val => {
      number.value += Number(val) - Number(number.value % val)

      select()
    }

    number.value = Number(props.miktar)

    watch(number, () => {
      select()
    })

    watch(
      () => props.miktar,
      (first, second) => {
        number.value = Number(props.miktar)
        console.log('number:' + props.miktar)
      },
    )

    const select = () => {
      emit('selected', { number: number.value, uid: props.uid })
    }

    const onBlurNumber = val => {
      number.value = Math.ceil(number.value / props.birimRitim) * props.birimRitim
    }

    // eslint-disable-next-line no-return-assign
    const minusNumber = val => {
      if (number.value == 0) {
        return true
      }
      if (number.value < props.birimRitim) {
        number.value = 0

        select()

        return true
      }

      number.value != 0
        ? (number.value -= Number(number.value % val) === 0 ? Number(val) : Number(number.value % val))
        : null

      select()
    }
    return {
      shoppingCartItems,
      Birim,
      number,
      select,
      BgColor,
      ButtonColor,
      BirimRitim,
      cMate,
      poNumber,
      deliveryDate,
      addNumber,
      minusNumber,
      disabled,
      onBlurNumber,
      addnewBasket,
      info,
      minSatis1,
      icons: {
        mdiPlusThick,
        mdiMinusThick,
      },
    }
  },
  methods: {},
}
</script>

<style lang="scss">
.scale-1 {
  transform: scale(0.85);
  transform-origin: center right;
}
.scale-2 {
  transform: scale(0.75);
  transform-origin: center right;
}
.completed {
  label,
  .v-text-field__suffix,
  input {
    color: #3b355a !important;
    text-shadow: none !important;
  }
}
.right-input {
  input {
    text-align: right;
  }
}
</style>
