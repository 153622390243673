<template>
  <div class="height2" style="text-align: left">
    <v-text-field
      @blur="getData"
      v-model="poData"
      class="height1"
      :label="$t('poNumarasi')"
      filled
      :label-class="customLabelClass"
    >
    </v-text-field>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
export default {
  data() {
    return {
      customLabelClass: 'custom-label-class',
    }
  },
  props: {
    uid: {
      default: null,
    },
    signalData: {
      type: Boolean,
    },
    temizle: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const id = props.uid
    const poData = ref('')
    const data1 = ref('')
    //console.log(id)
    const getData = () => {
      data1.value = poData.value
      emit('poData', data1.value)
      data1.value = ''
    }

    watch(
      () => store.state.signal,
      () => {
        getData()
        poData.value = ''
      },
    )

    return {
      getData,
      poData,
    }
  },
}
</script>

<style>
.custom-label-class {
  color: black !important; /* Siyah renk */
}
.height1 {
  height: 50px;
  display: flex;
  justify-content: flex-start;
}

.height2 {
  text-align: left;
}
</style>
