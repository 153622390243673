<template>
  <v-row no-gutters>
    <v-col>
      <div v-if="status && statusCustomer" id="data-list">
        <!-- app drawer -->

        <!-- <div class="text-center">
          <v-progress-circular
            class="text-center"
            v-show="yukleniyor"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div> -->

        <!-- <v-progress-linear color="white" indeterminate></v-progress-linear> -->

        <v-snackbars
          :objects.sync="snackbar"
          width="500"
          :timeout="1500"
          bottom
          right
          color="error"
          transition="slide-y-transition"
        >
          <template v-slot:default="{ message }">
            <div v-if="message.status">
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                Ürününden <strong> {{ message.count }} {{ message.birim }}</strong>
                <v-chip :color="message.color" label class="ml-1" small> {{ message.plant }} Fabrikasından </v-chip>
                sepetinize eklendi
              </div>
            </div>
            <div v-else>
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                <strong>{{ $t('sepettenCikarildi') }}</strong>
              </div>
            </div>
          </template>
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <v-snackbar v-model="isColorSnackbarVisible" bottom right color="success">
          <span style="font-size: 16px">
            <strong>{{ $t('urunBasariylaSepeteEklendi') }}</strong>
          </span>
        </v-snackbar>
        <v-snackbar v-model="isColorSnackbarVisibleError" bottom right color="error">
          <span style="font-size: 16px">
            <strong>{{ $t('lutfenUrunMiktariniGiriniz') }}</strong>
          </span>
        </v-snackbar>

        <v-dialog v-model="dialogDetail" persistent>
          <v-card>
            <v-card-title>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-icon large class="mr-2">
                  {{ icons.mdiFormatAlignCenter }}
                </v-icon>
                {{ $t('malzemeSecimEkrani') }}
              </div>

              <v-spacer />
              <v-btn color="error" depressed @click="closeDialog()">
                {{ $t('kapat') }}
                <v-icon right>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-row style="display: flex; flex-wrap: wrap; flex: 1 1 auto; margin: 0px; align-items: center">
              <v-col cols="12" md="3">
                <div class="mb-6">
                  <h4>{{ $t('malzemeGrubu') }}</h4>
                  <v-autocomplete
                    v-model="secilenKategori"
                    :label="$t('kategori')"
                    :items="productCategories"
                    hide-details
                    rounded
                    class="rounded-lg mt-2"
                    filled
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  <h4>{{ $t('urun') }}</h4>
                  <v-text-field
                    v-model="tableSearch"
                    :label="$t('arama')"
                    :placeholder="$t('urunKodunuVeyaAdınıYazabilirsiniz')"
                    persistent-placeholder
                    class="rounded-lg mt-2"
                    filled
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  <h4>{{ $t('musteriKodu') }}</h4>
                  <v-text-field
                    v-model="tableSearchCust"
                    :label="$t('arama')"
                    :placeholder="$t('musteriKodunuGiriniz')"
                    persistent-placeholder
                    class="rounded-lg mt-2"
                    filled
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  <v-checkbox
                    v-model="checkbox1"
                    hide-details
                    :label="$t('satislarimdanGetir')"
                    class="black-label"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div>
                  <v-btn color="secondary" @click="searchData()">{{ $t('ara') }}</v-btn>
                </div>
              </v-col>
            </v-row>

            <div class="mr-3" align="right">
              <download-excel :data="json_data" :fields="json_fields" name="Malzeme_Listesi_HATKO.xls">
                <v-btn plain text large>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  {{ $t('indir') }}
                </v-btn>
              </download-excel>
            </div>

            <v-card-text>
              <!-- MODAL TABLO
            :server-items-length="totalUserListTable"-->
              <v-data-table
                v-model="selectedRows"
                :options.sync="options"
                :loading="loading"
                :headers="tabloBasliklar"
                :items="dataListTable"
                :items-per-page="15"
              >
                <template #[`item.kresim`]="{ item }">
                  <v-tooltip v-if="item.kresim" right color="white">
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        v-bind="attrs"
                        tile
                        class="rounded d-flex align-center justify-center"
                        :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                        :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                        :color="item.pictures ? 'white' : 'primary'"
                        v-on="on"
                      >
                        <v-img
                          v-if="item.kresim"
                          :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                          :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                          :src="item.kresim"
                        ></v-img>
                        <v-icon v-else>
                          {{ icons.mdiCameraOff }}
                        </v-icon>
                      </v-card>
                    </template>
                    <v-img width="250" height="250" :src="item.bresim"></v-img>
                  </v-tooltip>
                  <v-card
                    v-else
                    tile
                    class="rounded d-flex align-center justify-center"
                    :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :color="item.pictures ? 'white' : 'primary'"
                  >
                    <v-img
                      v-if="item.kresim"
                      :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                      :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                      :src="item.kresim"
                    ></v-img>

                    <v-icon v-else>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-card>
                </template>

                <template v-slot:header.tekirdag_fabrikasi="{ item }">
                  <div class="my-2">
                    <div class="d-flex align-ledt justify-space-between">
                      <div>{{ $t('minSatisAdet') }}</div>
                      <div v-if="!$vuetify.breakpoint.xsOnly" style="width: 210px">{{ $t('miktar') }}</div>
                      <div v-else>{{ $t('miktar') }}</div>
                    </div>
                  </div>
                </template>

                <template #[`item.tekirdag_fabrikasi`]="{ item }">
                  <div class="d-flex align-center">
                    <div v-if="!$vuetify.breakpoint.xsOnly" class="text-caption font-weight-bold" style="width: 100px">
                      <div class="font-weight-bold" style="line-height: 1">
                        {{ item.min_satis_adeti }}
                      </div>
                    </div>
                    <div class="ml-0 pa-0">
                      <Counter
                        :uid="item.id"
                        :birim="item.birim"
                        :birimRitim="item.birim_ritim"
                        :miktar="item.product_pic_01"
                        :paym-cond="item.paymCond"
                        :minSatis="item.min_satis_adeti"
                        plant-id="01"
                        bg-color="transparent elevation-0"
                        button-color="white"
                        :status="true"
                        :filled="false"
                        :solo="true"
                        :sonVeri="sonData"
                        :tarih="deliveryDateAlinan"
                        :guncelle="gunceleSinyal"
                        :cMaterial1="item.cmaterial"
                        :signalData="sinyalVeri"
                        @selected="selected"
                        @gidenveri="gelen"
                        @sinyal="sinyal"
                        @refreshBasket="refreshBasket()"
                      ></Counter>
                    </div>
                  </div>
                </template>

                <template #[`item.poNumber`]="{ item }">
                  <div>
                    <div>
                      <PoNumber :temizle="clear" :uid="item.id" @poData="poData" :signalData="sinyalVeri"> </PoNumber>
                    </div>
                  </div>
                </template>

                <template #[`item.teslimTarihi1`]="{ item }">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <TeslimTarihi
                        :temizle="clear"
                        :uid="item.id"
                        @deliveryDate="deliveryDate"
                        :signalData="sinyalVeri"
                      >
                      </TeslimTarihi>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
            :flat="!$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? 197 : 87"
          >
            <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown" class="text-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiAccountSupervisor }}
              </v-icon>
              {{ $t('siparisGiris') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn color="secondary" dark @click="openDialog()" class="ml-2">
                    {{ $t('malzemeEkle') }}
                    <v-icon dark right>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="5">
                  <input id="fileInput" type="file" @change="addExcell" clearable style="display: none" />
                  <label color="secondary" for="fileInput" class="custom-file-upload white--text"
                    >{{ $t('topluMalzemeEkle') }}
                    <v-icon dark right>
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </label>
                </v-col>
                <v-col cols="12" md="4">
                  <a href="https://portalapi.hatko.com//templates/uploads/files/files/Hatko_S%CC%A7ablon%20(1).xlsx">
                    <v-btn color="warning" dark>
                      {{ $t('sablonIndir') }}

                      <v-icon dark right>
                        {{ icons.mdiDownload }}
                      </v-icon>
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </div>
          </v-toolbar>

          <!--
            <v-file-input
              class="ml-1 mt-4"
              :label="$t('topluMalzemeEkle')"
              type="file"
              @change="handleFileChange($event)"
              accept=".xlsx"
            />
          

          Ane Ekran Tablo
         :items="dataListTable"
    :server-items-length="totalUserListTable"-->
          <v-card-text
            v-if="$store.state['app-order'].basketItemCount > 0"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'pt-0'"
          >
            <v-list class="pa-0" color="grey" :dense="!$vuetify.breakpoint.mdAndUp">
              <div v-for="(line, index) in shoppingCartItems.detail" :key="`LIST-${index}`">
                <v-hover v-slot="{ hover }">
                  <v-list-item
                    :three-line="!$vuetify.breakpoint.mdAndUp"
                    :two-line="$vuetify.breakpoint.mdAndUp"
                    :class="hover ? 'grey' : null"
                    class="px-2"
                  >
                    <v-list-item-avatar
                      v-if="$vuetify.breakpoint.mdAndUp"
                      :size="$vuetify.breakpoint.mdAndUp ? 75 : 50"
                    >
                      <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                        <v-img
                          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :src="line.resim ? line.resim : require('@/assets/images/misc/not-image.png')"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-action>
                      <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 190 : 100" color="transparent">
                        <CounterTo
                          :uid="line.uid"
                          :miktar="line.qty"
                          :birim="line.birim"
                          :paym-cond="line.paymCond"
                          :birim-ritim="line.birimRitmi"
                          bg-color="counter"
                          button-color="black"
                          :light="true"
                          :poNum="line.sasNo"
                          :tarih="line.deliveryDate"
                          :signalData="sinyalVeri"
                          :guncelle="gunceleSinyal"
                          @selected="selected"
                          @refreshBasket="refreshBasket()"
                        />
                      </v-sheet>
                    </v-list-item-action>

                    <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-0 align-self-center'">
                      <v-list-item-title class="text-caption" style="color: rgb(63, 63, 63)">
                        {{ line.baslik }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-1" style="color: SlateGray">
                        {{ $t('urunKodu') }} : {{ line.urun.urun_kodu }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="mt-1" style="color: SlateGray">
                        {{ $t('urunMOQ') }} : {{ line.min_satis_adeti }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="line.cMaterial" class="mt-1" style="color: SlateGray">
                        <div>{{ $t('musteriKodu') }} : {{ line.cMaterial }}</div>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="!line.sasNo.startsWith('_def')" class="mt-1" style="color: SlateGray">
                        <div>{{ $t('poNumarasi') }} : {{ line.sasNo }}</div>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-if="line.deliveryDate != '1970-01-01'"
                        class="mt-1"
                        style="color: SlateGray"
                      >
                        <div>{{ $t('teslimTarihi') }} : {{ line.deliveryDate }}</div>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="mt-1 font-weight-medium" style="color: SlateGray">
                        <div>{{ $t('birimFiyat') }} : {{ line.birimFiyatPF | currency }}</div>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="mt-1" style="color: SlateGray">
                        <h4>
                          <div>{{ $t('tutar') }} {{ line.satirToplam | currency }}</div>
                        </h4>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="!$vuetify.breakpoint.mdAndUp || hover" class="ml-0">
                      <v-btn icon @click="deleteBasketItem(line.baslik, line.localCode, line.qty)">
                        <v-icon>
                          {{ icons.mdiTrashCan }}
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
                <v-divider></v-divider>
              </div>

              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right" style="color: SlateGray">
                    {{ $t('araToplam') }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                    {{ toplamBrutFiyat | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right" style="color: SlateGray">
                    {{ $t('indirim') }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                    -{{ toplamIndirimTutari | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right" style="color: SlateGray"> {{ $t('kdv') }} </v-list-item-title>
                  <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                    {{ satirToplamKDV | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right" style="color: SlateGray">
                    {{ $t('toplamFiyat') }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-right font-weight-bold success--text"
                    style="color: rgb(63, 63, 63)"
                  >
                    {{ toplamFiyat | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions class="px-2 px-md-4 pt-6 pt-md-2">
              <v-btn
                v-if="!errorShow"
                :plain="!$vuetify.breakpoint.mdAndUp"
                :small="!$vuetify.breakpoint.mdAndUp"
                text
                outlined
                class="text-capitalize"
                style="color: rgb(63, 63, 63)"
                @click="trashBasket()"
              >
                <v-icon left style="color: rgb(244, 82, 82)">
                  {{ icons.mdiTrashCan }}
                </v-icon>
                {{ $t('sepetiTemizle') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>

            <v-col class="mt-3">
              <v-row justify="space-between">
                <v-col cols="6">
                  <v-card color="grey lighten-1" class="mb-12">
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon x-large left>
                            {{ icons.mdiTruckFast }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('teslimatAdresi') }}</v-list-item-title>
                          <v-list-item-subtitle> {{ address_title }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-card-text>
                      <template>
                        <v-item-group v-model="teslimatAddressSelect" mandatory>
                          <v-row>
                            <v-col v-for="item in addressItems" :key="`${item.id}-address`" cols="12" md="6">
                              <v-item v-slot="{ active, toggle }">
                                <v-card
                                  :color="active ? 'secondary' : 'nightDarkTon'"
                                  class="pa-3"
                                  dark
                                  height="150"
                                  @click="toggle"
                                >
                                  <v-chip :color="active ? 'info' : 'primary'" small label>
                                    {{ item.address_title }}
                                  </v-chip>
                                  <div class="pt-3 white--text font-weight-bold">
                                    {{ item.city_txt }} / {{ item.state }}
                                  </div>
                                  <div
                                    class="pt-1 white--text"
                                    style="line-height: 1.25; font-size: 13px"
                                    :class="active ? ' font-weight-medium' : null"
                                  >
                                    {{ item.address }}
                                  </div>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </template>
                    </v-card-text>

                    <v-divider class="my-3"></v-divider>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card color="grey lighten-1" class="mb-12">
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon x-large left>
                            {{ icons.mdiNoteText }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('faturaAdresi') }}</v-list-item-title>
                          <v-list-item-subtitle> {{ address_title }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-card-text>
                      <template>
                        <v-item-group v-model="faturaAddressSelect" mandatory>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-item>
                                <v-card color="secondary" class="pa-3" dark height="150">
                                  <v-chip color="info" small label>
                                    {{ addressItems[0].address_title }}
                                  </v-chip>
                                  <div class="pt-3 white--text font-weight-bold">
                                    {{ addressItems[0].city_txt }} / {{ addressItems[0].state }}
                                  </div>
                                  <div
                                    class="pt-1 white--text font-weight-medium"
                                    style="line-height: 1.25; font-size: 13px"
                                  >
                                    {{ addressItems[0].address }}
                                  </div>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </template>
                    </v-card-text>

                    <v-divider class="my-3"></v-divider>
                  </v-card>
                </v-col>
              </v-row>

              <div
                class="mt-3 mb-6 mr-2 rounded-pill"
                style="text-align: center; background: linear-gradient(to right, #4e9d6e, #00d2d6)"
              >
                <v-alert text dense dark class="rounded-pill">
                  <div class="white--text">
                    <strong>{{ xx }}</strong> {{ $t('gunVadeli') }}
                  </div>
                </v-alert>
              </div>

              <div
                class="mb-5 mr-2 rounded-pill"
                style="text-align: center; background: linear-gradient(to right, #97164c, #e72323)"
              >
                <v-alert dense text class="rounded-pill">
                  <v-icon class="mr-3" color="white">{{ icons.mdiAlert }}</v-icon>
                  <span class="font-weight-medium white--text">{{ $t('siparisOnaylanmazsa') }}</span></v-alert
                >
              </div>

              <div style="text-align: right">
                <v-btn
                  v-if="!errorShow"
                  color="tertiary"
                  class="text-capitalize white--text mb-9"
                  x-large
                  depressed
                  :loading="loading"
                  @click="basketStep3()"
                >
                  {{ $t('siparisTamamla') }}
                </v-btn>
              </div>
            </v-col>
          </v-card-text>
          <div v-else class="d-flex align-center justify-center" style="height: calc(100% - 300px)">
            <div class="text-center">
              <v-icon color="primary" size="100">
                {{ icons.mdiBasketOff }}
              </v-icon>
              <div class="text-body-2 pt-1 pb-6">{{ $t('sepetinizBos') }}</div>
            </div>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Counter from '@/components/Counter.vue'
import CounterTo from '@/components/CounterTo.vue'
import CustomerList from '@/components/CustomerList.vue'
import PoNumber from '@/components/PoNumber.vue'
import TeslimTarihi from '@/components/TeslimTarihi.vue'
import i18n from '@/plugins/i18n'
import store from '@/store'
import { getVuetify, useRouter } from '@core/utils'
import * as XLSX from 'xlsx'

import {
  mdiArrowLeft,
  mdiBasketOff,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiAlert,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiFormatAlignCenter,
  mdiMagnify,
  mdiPlus,
  mdiDownload,
  mdiPlusThick,
  mdiSquareEditOutline,
  mdiTrashCan,
  mdiTruckFast,
  mdiNoteText,
  mdiMapMarker,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import orderStoreModule from '../orderStoreModule'
import vuetify from '@/plugins/vuetify'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  computed: {
    satirToplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.satirToplamFiyat
    },
    satirToplamKDV() {
      return this.shoppingCartItems?.toplamlar?.satirToplamKDV
    },
    toplamBrutFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamBrutFiyat
    },
    toplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamFiyat
    },
    toplamIndirimTutari() {
      return this.shoppingCartItems?.toplamlar?.toplamIndirimTutari
    },
    urunSayisi() {
      return this.shoppingCartItems?.toplamlar?.urunSayisi
    },

    toplamPaketSayisi() {
      return this.shoppingCartItems?.toplamlar?.toplamPaketSayisi
    },

    address_title() {
      let result = null
      if (this.addressItems.length > 0) {
        result = this.addressItems[this.teslimatAddressSelect]?.address_title
      }

      return result
    },
  },
  components: {
    CounterTo,
    Counter,
    VSnackbars,
    CustomerList,
    PoNumber,
    TeslimTarihi,
  },
  setup() {
    const { router } = useRouter()
    const formsale1 = ref(1)
    const checkbox1 = ref(true)
    const shoppingCartItems = ref([])
    const recievedData = ref(null)
    const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menu2 = ref(false)
    const menuref = ref(null)
    const bankalar = ref([])
    const errorShow = ref(false)
    const isColorSnackbarVisible = ref(false)
    const isColorSnackbarVisibleError = ref(false)
    const sonData = ref('')
    const clear = ref('')
    const yukleniyor = ref(false)
    const materialData = ref('')
    const addressItems = ref([])
    const sinyalVeri = ref(null)
    const gunceleSinyal = ref(false)
    const deliveryDateAlinan = ref('')
    const selectedItem = ref(null)
    const comboBoxItems = ['Terminaller', 'Konnektörler', 'Montajlı Ürünler', 'Diğer Ürünler']
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const dialogDetail = ref(false)
    const productCategories = ref([])
    const secilenKategori = ref('')
    const signal = ref(false)
    const teslimatAddressSelect = ref([])
    const responseStep2 = ref({})
    const faturaAddressSelect = ref([])
    const namaSevkAddressItems = ref([])
    const bankItem = ref(0)
    const cargoPrice = ref(0)
    const tableSearch = ref('')
    const tableSearchCust = ref('')
    const json_data = ref([])
    const detail1 = ref({})

    const xx = ref('')

    const alarm = () => {
      Vue.swal({
        title: i18n.t('lutfenBekleyiniz'),
        html: i18n.t('siparisinizOlusturuluyor'),
        timerProgressBar: true,
        showLoading: true,
        showConfirmButton: false,
        timer: 20000,
      })
    }

    const basketStep3 = async () => {
      loading.value = true

      const namaSevkMusteriChange = () => {
        postData({
          method: 'getAddressListByCustNo',
          customer: namaSevkMusteri.value,
        }).then(response => {
          if (response.error === 0) {
            namaSevkAddressItems.value = response.response
          } else {
            namaSevkAddressItems.value = []
          }
        })
      }

      const payload = {
        deliveryAddr:
          namaSevkAddressItems.value?.[teslimatAddressSelect.value]?.id ||
          addressItems.value?.[teslimatAddressSelect.value]?.id,
        invoiceAddr:
          namaSevkAddressItems.value?.[faturaAddressSelect.value]?.id ||
          addressItems.value?.[faturaAddressSelect.value]?.id,
        cargo: 9,
      }
      store.dispatch('app-order/fetchMakeOrderStep1', payload).then(result => {
        alarm()
        if (result.error === 0) {
          console.log(payload.deliveryAddr)
          store.dispatch('app-order/fetchGetOrderStep2Data').then(response => {
            responseStep2.value = response

            const payloadPay = {
              method: 'makeOrderStep2',
              paymentType: 2,
              banka: bankalar.value[bankItem.value]?.id,
            }

            store.dispatch('app-order/fetchMakeOrderStep2', payloadPay).then(() => {
              store.dispatch('app-order/fetchGetOrderStep3Data').then(response => {
                //mesafeliSatisSozlesmesi.value = response.mesafeliSatisSozlesmesi
                //onBilgilendirmeSozlesmesi.value = response.onBilgilendirmeSozlesmesi
                const container = document.querySelector('#container')

                //container.scrollTop = 0
                cargoPrice.value = responseStep2.value.detail?.ordering?.totalCargoAmount || 0

                //toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                //toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0

                store.dispatch('app-order/fetchMakeOrderStep3').then(async response => {
                  yukleniyor.value = true
                  if (response.error === 0) {
                    store.commit('app/TOGGLE_BASKET_DRAWER', false)
                    store.dispatch('app-order/fetchGetOrderStep4Data').then(response => {
                      if (response.error === 1) {
                        Vue.swal({
                          title: 'Hata1',
                          html: response.msg,
                          icon: 'error',
                          background: '#FF4C51',
                          timerProgressBar: true,
                          showConfirmButton: false,
                        })
                        loading.value = false
                        yukleniyor.value = false
                      } else {
                        detail1.value = response.detail
                        store.commit('SET_DETAIL', response.detail)
                        status.value = true
                        store.dispatch('app-order/fetchBasketItemCount')
                        router.push({ name: 'order-completed' })
                      }
                    })
                  } else {
                    loading.value = false
                    yukleniyor.value = false
                    paymentErrorMsg.value = response.msg
                    paymentError.value = true
                    Vue.swal({
                      title: 'Hata1',
                      html: response.msg,
                      icon: 'error',
                      background: '#FF4C51',
                      timerProgressBar: true,
                      showConfirmButton: false,
                    })
                  }
                })
              })
            })
          })
        } else {
          loading.value = false
          Vue.swal({
            title: 'Hata2',
            html: result.msg,
            icon: 'error',

            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)

    const tabloBasliklar = [
      {
        text: i18n.t('resim'),
        value: 'kresim',
        width: '40px',
        sortable: false,
      },
      {
        text: i18n.t('musteriKodu'),
        value: 'cmaterial',
        width: '140px',
        sortable: false,
      },
      {
        text: i18n.t('kod'),
        value: 'product_code',
        width: '140px',
        sortable: false,
        filter: value => {
          if (!product_code.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_code.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      {
        text: i18n.t('urunAdi'),
        value: 'product_title',
        sortable: false,
        class: 'min-width-200',
        filter: value => {
          if (!product_title.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_title.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      {
        text: i18n.t('poNumarasi'),
        value: 'poNumber',
        width: '200px',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('teslimTarihi'),
        value: 'teslimTarihi1',
        width: '200px',
        align: 'center',
        sortable: false,
      },

      {
        text: '',
        value: 'tekirdag_fabrikasi',
        class: 'plant_tekirdag',
        width: '200px',
        sortable: false,
      },
    ]
    if (window.screen.availWidth <= 600) {
      tabloBasliklar.forEach(item => {
        delete item.filter
      })
    }

    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const tipItems = ref([
      { text: 'Ön Ödemelli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı (Yapım Aşamasında)', value: 'O9' },
    ])
    const tipDeger = ref()
    const delivery_date = store.state['app-order'].deliveryDate
    const excelData = ref([])

    const addExcell = event => {
      console.log(1)
      const file = event.target.files[0]
      const reader = new FileReader()
      console.log(2)

      reader.onload = e => {
        console.log(3)
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        console.log(4)

        // Diziyi oluştur
        const dataArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        console.log(dataArray)

        excelData.value = dataArray
        console.log(excelData.value)
        const donusenDizi = JSON.stringify(excelData.value)
        console.log(5)
        console.log('json =' + donusenDizi)

        let veriler = {
          method: 'addToBasketByMAT',
          jsondata: donusenDizi,
        }

        store.dispatch('app-order/fetchAddToBasket', veriler).then(response => {
          if (!response.error) {
            store.dispatch('app-order/fetchActiveBasketList1').then(response => {
              if (!response.error) {
                shoppingCartItems.value = response
                //sepetLoading.value = false
              } else {
                console.table('hatalı =>', response)
              }
            })
          } else {
            console.table('hatalı =>', response)
          }
        })

        /*
         for (var i = 0; i < excelData.value.length; i++) {
          function excelTarihCevir(excelTarih) {
            return new Date((excelTarih - 1) * 24 * 60 * 60 * 1000).toLocaleDateString('tr-TR')
          }

          // Örnek kullanım:
          var excelTarih = excelData.value[i].TeslimTarihi
          var insanAnlasilirTarih = excelTarihCevir(excelTarih)
          console.log('yeni tariih : ' + insanAnlasilirTarih)
        }
        */
      }

      reader.readAsArrayBuffer(file)
      event.target.value = null
    }

    const openDialog = () => {
      dialogDetail.value = true
      signal.value = !signal.value
      sinyalVeri.value = signal.value
      console.log('giden sinyal = ' + signal.value)
      console.log(router.currentRoute)
    }
    const searchData = () => {
      //fetchBasket()
      loading.value = true
      fetchDatas()

      signal.value = !signal.value
      gunceleSinyal.value = signal.value
    }

    const closeDialog = () => {
      dialogDetail.value = false
      signal.value = !signal.value
      sinyalVeri.value = signal.value
      console.log('giden sinyal = ' + sinyalVeri.value)

      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
          //sepetLoading.value = false
        } else {
          console.table('hatalı =>', response)
        }
      })

      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getAddressListByUid',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            addressItems.value = response.response
            errorShow.value = false

            //  console.table('başarılı =>', response)
          } else {
            errorShow.value = true
            console.table('hatalı =>', response)
          }
        })

      /*

      store
        .dispatch('app-order/fetchBankaList', {
          method: 'getBankaTransferList',
        })
        .then(response => {
          if (!response.error) {
            //bankalar.value = response.detail
            console.table('başarılı =>', response)
          } else {
            console.table('hatalı =>', response)
          }
        })

        */
    }

    /*
    const plasyirFn = () => {
      postData({
        method: 'getKotaList',
        delivery_date,
      }).then(response => {
        kotaItems.value = []
        if (response.error === 0) {
          kotaItems.value = response.response.map(({ limit, sKullanilan, limitNum, notes }) => ({
            text: notes,
            value: limitNum,
            limit,
            sKullanilan,
          }))
        }
        kotaItems.value.unshift({
          text: 'Kota Kullanmadan Devam Et',
          value: '0',
        })
      })

      postData({
        method: 'getMemberPaymentConditions',
        customer: store.state.plasiyerCustomer,
      }).then(response => {
        kondisyonItems.value = response.map(({ paymcondtxt, paymcond }) => ({
          text: paymcondtxt,
          value: paymcond,
        }))
      })
    }
*/

    onMounted(() => {
      xx.value = localStorage.getItem('paymcondtxt')
      fetchCategories()
      //plasyirFn()
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        loading.value = false
        statusCustomer.value = true
      }

      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
          //sepetLoading.value = false
        } else {
          // console.table('hatalı =>', response)
        }
      })

      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getAddressListByUid',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            addressItems.value = response.response
            errorShow.value = false

            //  console.table('başarılı =>', response)
          } else {
            errorShow.value = true
            console.table('hatalı =>', response)
          }
        })
      /*

      store
        .dispatch('app-order/fetchBankaList', {
          method: 'getBankaTransferList',
        })
        .then(response => {
          if (!response.error) {
            //bankalar.value = response.detail
            console.table('başarılı =>', response)
          } else {
            console.table('hatalı =>', response)
          }
        })
        */
    })

    const fetchCategories = val => {
      productCategories.value = []
      const id = val
      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getCategories'
      store
        .dispatch('postMethod', {
          method,
        })
        .then(response => {
          if (response.error === true) {
            return console.log('error')
          }
          productCategories.value.push({
            value: val,
            text: localStorage.getItem('lang') === 'tr' ? 'Hepsi' : 'All',
          })

          for (const [key, value] of Object.entries(response.categoryList)) {
            productCategories.value.push({
              value: value.id,
              text: value.kategori,
            })
          }
        })
    }

    const refreshBasket = () => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
        } else {
          //   console.table('hatalı =>', response)
        }
      })
    }

    const trashBasket = () => {
      Vue.swal({
        title: i18n.t('eminMisin'),
        text: i18n.t('sepetiTemizlemekIstiyorMusun'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: i18n.t('onayliyorum'),
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              //fetchBasket()
              //store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: i18n.t('sepetTemzilendi'),
                text: i18n.t('islemBasarili'),
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            })
          })
        }
      })
    }

    const kotaChange = () => {
      postData({
        method: 'setMemberKota',
        limitnum: kotaDeger.value,
        delivery_date,
      }).then(response => {
        const validFromRaw = response.detail.validFrom.slice(0, 10)
        const validFromSplit = validFromRaw.split('.')
        const validFrom = `${validFromSplit[2]}-${validFromSplit[1]}-${validFromSplit[0]}`

        const validUntilRaw = response.detail.validUntil.slice(0, 10)
        const validUntilSplit = validUntilRaw.split('.')
        const validUntil = `${validUntilSplit[2]}-${validUntilSplit[1]}-${validUntilSplit[0]}`

        store.commit('app-order/DELIVERY_DATE_MAX', validUntil)
        store.commit('app-order/DELIVERY_DATE_MIN', validFrom)
      })
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }

    const tipChange = () => {
      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }

    const refreshCustomer = () => {
      loading.value = true
      kondisyonItems.value = []
      kotaItems.value = []
      kondisyonDeger.value = ''
      kotaDeger.value = ''
      tipDeger.value = ''
      statusCustomer.value = false
      //plasyirFn()
      //fetchBasket()
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        loading.value = true
        kondisyonItems.value = []
        kotaItems.value = []
        kondisyonDeger.value = ''
        kotaDeger.value = ''
        tipDeger.value = ''
        statusCustomer.value = false
        //plasyirFn()
        //  fetchBasket()
        //refreshBasket()
      },
    )

    /*

    watch(
      () => store.state['app-order'].basketItemCount,
      () => {
        fetchDatas()
      },
    )
*/

    const deleteBasketItem = (baslik, id) => {
      store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', false)
      Vue.swal({
        title: i18n.t('eminMisin'),
        text: i18n.t('silmeIsleminiOnaylayin'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: i18n.t('onayliyorum'),
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchDeleteBasketItem', { id }).then(response => {
            if (response.error === 0) {
              snackbar.value.push({
                message: `${baslik} Ürünü Silindi`,
                color: 'error',
                timeout: 3000,
              })
              store.dispatch('app-order/fetchActiveBasketList').then(response => {
                if (!response.error) {
                  shoppingCartItems.value = response
                  sinyalVeri.value = !sinyalVeri.value
                  if (typeof response.detail === 'undefined') {
                    store.commit('app/TOGGLE_BASKET_DRAWER', false)
                  }

                  //     store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', true)
                } else {
                  store.commit('app/TOGGLE_BASKET_DRAWER', false)
                }
              })
            } else {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            }
          })
        }
      })
    }

    const ilaveDevam = () => {
      // değişen yer  if (kotaDeger.value && kondisyonDeger.value && tipDeger.value) {
      if (kondisyonDeger.value && tipDeger.value) {
        status.value = true
        statusCustomer.value = true
        // fetchBasket()
        //fetchDatas()
      }
    }

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])
    const count = ref(1)
    const selected = item => {
      //fetchBasket()
    }
    const itemRowBackground = item => (item.selected ? 'style-1' : 'style-2')

    watch(
      () => checkbox1.value,
      () => {
        if (checkbox1.value == true) {
          formsale1.value = 1
        } else {
          formsale1.value = 0
        }

        // fetchBasket()
        // refreshBasket()
      },
    )

    const fetchBasket = async () => {
      await store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          fetchDatas(response.detail)
        } else {
          // console.table('hatalı =>', response)
        }
      })
    }

    const fetchDatas = async basketList => {
      const id = secilenKategori.value
      const page = 1
      const count = 6
      const plant = '01'
      const fromSale = formsale1.value
      const prdName = tableSearch.value
      const custmatcode = tableSearchCust.value
      const method = 'getProductsByCtgId'

      const delivery_date = store.state['app-order'].deliveryDate

      await store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
          delivery_date,
          fromSale,
          prdName,
          custmatcode,
        })
        .then(response => {
          response.detail.products.forEach(item => {
            item.satis_fiyati_pf = String(item.satis_fiyati_pf).replace(/\./g, ',')
            item.urun_kodu = "'" + item.urun_kodu
          })
          const paymcond = kondisyonDeger.value == '100' ? kondisyonDeger.value : `0${kondisyonDeger.value}`

          const productList = response.detail.products.map(item => {
            var items = {}

            items.id = item.id
            items.product_code = item.urun_kodu
            items.cmaterial = item.cmaterial
            items.product_title = item.baslik
            items.paymCond = kondisyonDeger.value
            items.pictures = item.resim
            items.bresim = item.bresim
            items.kresim = item.kresim
            items.min_satis_adeti = item.min_satis_adeti
            items.birim_ritim = item.birim_ritmi
            items.product_01 = true
            materialData.value = item.cmaterial
            items.priceAll = item.priceAll
            items.product_price_01 = item.satis_fiyati_pf

            if (item.priceAll.length > 0) {
              const price_01 = item.priceAll.find(i => i.plant === '01')
              if (price_01) {
              }
            }
            if (typeof items.product_price_01 === 'undefined') {
              items.product_01 = false
            }

            // if (item.priceAll.length > 0) {
            //   const price_01 = item.priceAll.find(i => i.paymcond === '01')
            //   const price_02 = item.priceAll.find(i => i.paymcond === '02')
            //   if (price_01) {
            //     items.product_price_01 = price_01.price1
            //   } else {
            //     items.product_01 = false
            //   }
            //   if (price_02) {
            //     items.product_price_02 = price_02.price1
            //   } else {
            //     items.product_02 = false
            //   }
            // } else {
            //   items.product_01 = false
            //   items.product_02 = false
            // }
            json_data.value = response.detail.products
            return items
          })

          if (basketList) {
            basketList.forEach(i => {
              productList.find(findItem => {
                if (findItem.id == i.uid) {
                  findItem.selected = true
                  findItem.paymCond = i.paymCond
                  //findItem.product_pic_01 = Number(i.qty)
                  if (i.selected_plant === '01') {
                    findItem.product_pic_01 = Number(i.qty)
                  } else if (i.selected_plant === '02') {
                    findItem.product_pic_02 = Number(i.qty)
                  }
                }
              })
            })
          }

          dataListTable.value = productList
          totalDataListTable.value = Number(response.detail.toplam)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    return {
      checkbox1,
      namaSevkAddressItems,
      basketStep3,
      addressItems,
      addExcell,
      openDialog,
      closeDialog,
      signal,
      searchData,
      errorShow,
      secilenKategori,
      excelData,
      trashBasket,
      shoppingCartItems,
      yukleniyor,
      refreshBasket,
      recievedData,
      isColorSnackbarVisible,
      isColorSnackbarVisibleError,
      sonData,
      clear,
      materialData,
      sinyalVeri,
      gunceleSinyal,
      deliveryDateAlinan,
      comboBoxItems,
      selectedItem,
      statusFind,
      userData,
      dataItem,
      dataListTable,
      bankalar,
      tabloBasliklar,
      totalDataListTable,
      loading,
      itemRowBackground,
      selected,
      options,
      dataTotalLocal,
      selectedRows,
      bankItem,
      fetchDatas,
      teslimatAddressSelect,
      faturaAddressSelect,
      count,
      alarm,
      snackbar,
      search,
      product_title,
      product_code,
      status,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      kondisyonChange,
      tipItems,
      xx,
      tipDeger,
      tipChange,
      detail1,
      ilaveDevam,
      refreshCustomer,
      deleteBasketItem,
      cargoPrice,
      dialogDetail,
      productCategories,
      json_data,
      tableSearch,
      tableSearchCust,

      // icons
      icons: {
        mdiBasketOff,
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFlash,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiDownload,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
        mdiAlert,
        mdiPlus,
        mdiFormatAlignCenter,
        mdiTrashCan,
        mdiMapMarker,
        mdiTruckFast,
        mdiNoteText,
      },
    }
  },
  data() {
    return {
      gidenPo: '',
      gidenTarih: '',
      recievedData: '',
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      message1: '',
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
      json_fields: {
        'Müşteri Kodu': 'cmaterial',

        'Ürün Kodu': 'urun_kodu',

        'Ürün Adı': 'baslik',

        Fiyat: 'satis_fiyati_pf',
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    handleFileChange(event) {
      console.log(1)
      const files = event.target.files
      console.log(2)
      if (files.length > 0) {
        console.log(3)
        this.processExcelFile(files[0])
      }
    },
    processExcelFile(file) {
      const reader = new FileReader()
      reader.onload = event => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        // Assuming the first sheet is the one you want to process
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]

        // Convert Excel data to JSON format
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        // Process jsonData as needed
        console.log(jsonData)
      }
      reader.readAsArrayBuffer(file)
    },

    gelen(data) {
      if (data == true) {
        this.isColorSnackbarVisible = true
      } else if (data == false) {
        this.isColorSnackbarVisibleError = true
      }
    },

    poData(data1) {
      this.sonData = data1
    },

    sinyal(signal) {
      this.clear = signal
    },

    deliveryDate(tarih) {
      this.deliveryDateAlinan = tarih
    },

    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';
/*
.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}
.textColor {
  color: white;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    color: white !important;
  }
  
  .search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}

.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}
  
  */
.v-select__selection {
  color: grey !important ;
}

.theme--light.v-label {
  color: rgb(206, 206, 206);
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(255, 255, 255, 0.87);
}

.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}
// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}
.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}

/*
  .style-2 {
    // background-color: rgb(114, 114, 67);
  }
  */

.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}
.paymCond-hidden {
  display: none !important;
}
.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}
/*
.custom-combobox {
  width: 500px !important;
  margin: 25px;
}
*/
.btn-responsive v-icon {
  margin-right: 15px;
}

@media (max-width: 600px) {
  .btn-label {
    display: none;
  }
}
.black-label .v-label {
  color: rgba(63, 63, 63, 0.749);
}
/*
.container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
*/

.custom-combobox {
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 480px) {
  .custom-combobox {
    flex-basis: 48%;
    max-width: 48%;
  }
}

@media (min-width: 768px) {
  .custom-combobox {
    flex-basis: 32%;
    max-width: 32%;
  }
}

@media (min-width: 1024px) {
  .custom-combobox {
    flex-basis: 24%;
    max-width: 24%;
  }
}

.custom-button {
  display: inline-flex; /* İç içe geçmiş elementlerin yan yana sıralanmasını sağlar */
  align-items: center; /* Dikey hizalama - ortala */
}

.custom-file-label {
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.custom-file-label:hover {
  cursor: pointer;
  background-color: #0056b3;
}
/*
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
}*/

.v-dialog > .v-card > .v-card__text {
  padding: 0 0px 16px;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #0083e8;
  border-radius: 6%;
  margin-left: 5px;
  padding-right: 20px;
  box-shadow: inherit;
}
</style>
